<template>
  <div>
    <div class="row q-pa-xs q-pt-sm border-bottom">
      <div>
        <img
          style="height: 60px; width: 70px; object-fit: contain;"
          :src="data.image || fallbackImage"
          :alt="data.name"
          @error="onImageLoadFailure"
        >
      </div>

      <div class="col q-px-sm">
        <div v-if="total !== undefined" class="text-caption">
          <span :class="color">
            {{ $t('Total') + ': ' }}
          </span>

          <span>
            {{ total }}
          </span>
        </div>

        <div class="text-subtitle2">
          {{ name }}
        </div>

        <div v-if="!hasChange && data.maxCount !== undefined">
          {{ `${$t('Left')}: ${data.maxCount}` }}
        </div>

        <div v-if="data.maxCount !== undefined" class="row q-py-xs">
          <text-change-detection
            v-show="hasChange"
            :key="`${data.id}:${data.sku}`"
            :value="data.maxCount"
            @change="hasChange = true"
            is-number
          />
        </div>
      </div>
    </div>

    <div class="q-py-xs q-px-sm text-caption">
      <div :class="color">
        {{ $t('SKU') }}
      </div>

      <div
        class="text-bold"
        style="margin-top: -4px;"
      >
        {{ data.sku || '--' }}
      </div>

      <div :class="color">
        {{ $t('Barcode') }}
      </div>

      <div
        class="text-bold"
        style="margin-top: -4px;"
      >
        {{ barcode || '--' }}
      </div>

      <div v-if="data.article" :class="color">
        {{ $t('Article') }}
      </div>

      <div
        v-if="data.article"
        class="text-bold"
        style="margin-top: -4px;"
      >
        {{ data.article }}
      </div>

      <div v-if="order" :class="color">
        {{ $t('Order') }}
      </div>

      <div
        v-if="order"
        class="text-bold"
        style="margin-top: -4px;"
      >
        {{ order }}
      </div>

      <items-table
        v-if="details"
        :items="data.items || []"
      />
    </div>
  </div>
</template>

<script>
// Components
import ItemsTable from '../items-table/ItemsTable.vue'
import TextChangeDetection from '../tools/TextChangeDetection.vue'

export default {
  name: 'OfferObject',
  components: {
    TextChangeDetection,
    ItemsTable
  },
  props: {
    details: {
      type: Boolean,
      defaut () {
        return false
      }
    },
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      hasChange: false,
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  computed: {
    name () {
      return this.data.name || `${this.$t('Id')}: ${this.data.id}`
    },
    order () {
      if (this.data.order) {
        return this.data.order
      }

      if (this.data._embedded && this.data._embedded.reserve && this.data._embedded.reserve._embedded && this.data._embedded.reserve._embedded.order) {
        const parts = this.data._embedded.reserve._embedded.order._links.self.href.split('/')
        return parts[parts.length - 1]
      }

      return ''
    },
    barcode () {
      if (!this.data.barcodes) {
        return ''
      }

      if (typeof this.data.barcodes === 'string') {
        return this.data.barcodes
      }

      return this.data.barcodes[this.data.barcodes.length - 1]
    },
    color () {
      return this.$q.dark.isActive
        ? 'text-white'
        : 'text-grey-9'
    },
    total () {
      return this.data.count
    }
  },
  methods: {
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    }
  }
}
</script>
